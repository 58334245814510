.position-relative {
  position: relative;
}

.download_icons {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 158.8%;
  color: #ffffff;
  padding-left: 5px;
}

.d-flex {
  display: flex;
}

.mb-4 {
  margin-bottom: 20px;
}
.checkout {
  display: inline-block;
  background-color: white;
  padding: 25px 15px;
  width: 180px;
  border-radius: 40px;
  font-weight: 700;
  /* color: var(--dark-blue); */
  text-decoration: none !important;
  color: #384c6d;
}

.link {
  padding: 20px 25px;
  font-size: 18px;
  padding: 20px 25px;
  font-weight: bolder;
}

.checkout:hover {
  color: var(--dark-blue) !important;
}
